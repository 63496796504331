<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <img v-if="content.cont_foto_destaque" :src="`${base_url}upload/content_photo/p/${content.cont_foto_destaque}`" height="50">
            <img v-if="!content.cont_foto_destaque" :src="`${base_url}upload/logo.png`" height="50">
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h2 class="lime--text lighten-1">{{ content.cont_titulo }}</h2>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-1" :to="{name: 'contents'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Idioma:</th>
                <td width="90%" colspan="5">
                    <img v-if="content.language" :src="`${base_url}upload/language/${content.language.lang_flag}`" class="avatar">
                </td>
            </tr>
            <tr>
                <th class="texto" width="10%">Resumo:</th>
                <td width="90%" colspan="5">{{ content.cont_resumo }}</td>
            </tr>
            <tr>
                <th width="10%">Fonte:</th>
                <td colspan="2">{{ content.cont_fonte }}</td>
                <th width="10%">Fonte (URL)</th>
                <td colspan="2">{{ content.cont_fonteurl }}</td>
            </tr>
            <tr>
                <th width="10%">Autor:</th>
                <td width="40%" colspan="2">{{ content.cont_autor }}</td>
                <th width="10%">Clicks:</th>
                <td width="40%" colspan="2">{{ content.cont_clicks }}</td>
            </tr>
            <tr>
                <th width="10%">Rascunho:</th>
                <td colspan="2">{{ (content.cont_rascunho == '1') ? 'Ativo' : 'Inativo' }}</td>
                <th width="10%">Revisado:</th>
                <td colspan="2">{{ (content.cont_revisado == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Publicado:</th>
                <td colspan="2">{{ (content.cont_publicado == '1') ? 'Ativo' : 'Inativo' }}</td>
                <th width="10%">Especial:</th>
                <td colspan="2">{{ (content.cont_especial == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Destaque Home</th>
                <td colspan="2">{{ (content.cont_destaque_home == '1') ? 'Ativo' : 'Inativo' }}</td>
                <th width="10%">Destaque 24h:</th>
                <td colspan="2">{{ (content.cont_destaque24h == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Destaque Video</th>
                <td colspan="2">{{ (content.cont_destaque_video == '1') ? 'Ativo' : 'Inativo' }} </td>
                <th width="10%">Código Vídeo</th>
                <td colspan="2">{{ content.cont_video }}</td>
            </tr>
            <tr>
                <th width="10%">É Link?</th>
                <td colspan="2">{{ (content.cont_islink == '1') ? 'SIM' : 'NÂO' }} </td>
                <th width="10%">Status</th>
                <td colspan="2">{{ (content.cont_status == '1') ? 'Ativo' : 'Inativo' }} </td>
            </tr>
            <tr>
                <th width="10%">Meta Descrição:</th>
                <td width="90%" colspan="5">{{ content.cont_meta_descricao }}</td>
            </tr>
            <tr>
                <th width="10%">Palavras Chaves:</th>
                <td width="90%" colspan="5">{{ content.cont_keywords }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%" text>texto:</th>
                <td width="90%" colspan="5" v-html="content.cont_texto"></td>
            </tr>
            <tr>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.loadContent()
    },
    name: "DetailContentComponent",
    props: {
        cont_id: {
            require: true
        },
    },
    data() {
        return {
            content: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadContent() {
            this.$store.dispatch('loadContent', this.cont_id)
                .then(response => {
                    this.content = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}

.table th.texto {
    vertical-align: top;
}
</style>
